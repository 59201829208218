import React from 'react'
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ContactForm from "./ContactForm";
function Contact() {
  return (
    <div>
        <header className="App-header">
      <Header />
      <ContactForm/>
      <Footer />
      </header>
    </div>
  )
}

export default Contact
