import Home from './components/Home/Home';
import MoreLocations from './components/MoreLocations/MoreLocations';
import Contact from './components/Contact/Contact';
import Us from './components/About/Us';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
     
     <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/more-locations" element={<MoreLocations />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<Us />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
