import React from 'react'
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import About from "./About";

function Us() {
  return (
    <div>
        <header className="App-header">
      <Header />
      <About/>
      <Footer />
      </header>
    </div>
  )
}

export default Us
