import React, { useState } from 'react';
// import axios from 'axios';
import './Contact.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailto = `mailto:test@example.com?subject=Contact from ${formData.name}&body=${formData.message}`;
    window.location.href = mailto;
  };

  return (
    <div className="contact-container">
      <h1>Send us your query</h1>
      <div className="form-group">
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Message:</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
      </div>
      <button type="button" onClick={handleSubmit}>Submit</button>
    </div>
  );
}

export default ContactForm;
