import React from 'react'
import Slider from "react-slick";
import './Hero.css';
import 'slick-carousel/slick/slick.css';
import "slick-carousel/slick/slick-theme.css";
// Import your images here
import image1 from './hero-image1.png';
// import image1 from '/../../hero-image1.png';
import image2 from './hero-image2.png';
import image3 from './hero-image1.png';
import slide3 from './slide3.jpeg';
import slide2 from './slide2.png';


const Hero = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
    };

    return (
        <div className="hero">
            <Slider {...settings}>
                <div className="slide">
                    <img src={slide2} alt="Slide 1" className="slide-image"/>
                </div>
                <div className="slide">
                    <img src={slide3} alt="Slide 2" className="slide-image"/>
                </div>
                {/* <div className="slide">
                    <img src={slide1} alt="Slide 3" className="slide-image"/>
                </div> */}
            </Slider>
        </div>
    );
}

export default Hero;
