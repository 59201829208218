import React from 'react'
import './Locations.css';
import location1 from './location1.jpeg'; // Replace with your image path
import location2 from './location2.jpeg'; // Replace with your image path
import location3 from './location3.jpeg'; // Replace with your image path
import { Link } from 'react-router-dom';


function More() {
    return (
        <div className="locations-container">
            <h1 className='locations-h1'>Photo Gallery</h1>
            <div className='location-row'>
            <div className="card">
                <img src={location1} alt="Location 1" className="location-image"/>
                <p>Introducing our brand new Luxury Builder Floors,where elegance meets comfort. Your dream home awaits in prime Gurgaon locations!</p>
                <span>Location: Prime Gurgaon</span><br></br>
                <span>Space: 250 square yards</span><br></br>
                <span>Configuration: 3/4 BHK</span><br></br>
            </div>
            
            <div className="card">
                <img src={location2} alt="Location 1" className="location-image"/>
                <p>Unveiling a Luxurious Farmhouse that redefines opulence! Set in the heart of Delhi NCR, this is your chance to own a piece of paradise.</p>
                <span>Location: Delhi NCR</span><br></br>
                <span>Space: A sprawling 5200 square yards</span><br></br>
                <span>Configuration: 7 BHK</span><br></br>
                {/* <span>Embrace Nature: Lush green surroundings and serenity await you at every corner.</span><br></br> */}
            </div>
            <div className="card">
                <img src={location3} alt="Location 1" className="location-image"/>
                <p>Unveiling a Luxurious Farmhouse that redefines opulence! Set in the heart of Delhi NCR, this is your chance to own a piece of paradise.</p>
                <span>Location: Chattarpur / Sultanpur / Western Greens in Delhi NCR</span><br></br>
                <span>Space: A sprawling 5200 square yards</span><br></br>
                <span>Configuration: 7 BHK</span><br></br>
            </div>
            
            </div>
            {/* <Link to="/more-locations" className="view-more-button">View More</Link> */}
            
            <div className='location-row'>
            <div className="card">
                <img src={location1} alt="Location 1" className="location-image"/>
                <p>Introducing our brand new Luxury Builder Floors,where elegance meets comfort. Your dream home awaits in prime Gurgaon locations!</p>
                <span>Location: Prime Gurgaon</span><br></br>
                <span>Space: 250 square yards</span><br></br>
                <span>Configuration: 3/4 BHK</span><br></br>
            </div>
            
            <div className="card">
                <img src={location2} alt="Location 1" className="location-image"/>
                <p>Unveiling a Luxurious Farmhouse that redefines opulence! Set in the heart of Delhi NCR, this is your chance to own a piece of paradise.</p>
                <span>Location: Delhi NCR</span><br></br>
                <span>Space: A sprawling 5200 square yards</span><br></br>
                <span>Configuration: 7 BHK</span><br></br>
                {/* <span>Embrace Nature: Lush green surroundings and serenity await you at every corner.</span><br></br> */}
            </div>
            <div className="card">
                <img src={location3} alt="Location 1" className="location-image"/>
                <p>Unveiling a Luxurious Farmhouse that redefines opulence! Set in the heart of Delhi NCR, this is your chance to own a piece of paradise.</p>
                <span>Location: Chattarpur / Sultanpur / Western Greens in Delhi NCR</span><br></br>
                <span>Space: A sprawling 5200 square yards</span><br></br>
                <span>Configuration: 7 BHK</span><br></br>
            </div>
            
            </div>
            <div className='btn'>
            
            </div>
            
        </div>
    );
  
}

export default More;
