import React from 'react'
import './About.css';
import aboutImage from './about-image.png'; // Replace with your image path


function About() {
  return (
    <div className="about-container">
    {/* <div className="image-section">
        <img src={aboutImage} alt="About Us" className="about-image"/>
    </div> */}
    <div className="text-section">
        <h1>About Us</h1>
        <p>At VMKM Realtors, we're dedicated to delivering a world of luxury real estate experiences. With a profound commitment to excellence, we specialize in the sale and purchase of opulent builder floors, exquisite apartments, elegant villas, and sprawling farmhouses in prime locations across South Delhi, Gurgaon, and the idyllic landscapes of Goa.
        </p>
        <h2>Our Passion for Premium Living</h2>
        <span>We understand that a home is not just a place; it's a statement, an embodiment of your dreams and aspirations. Our passion lies in curating a portfolio of exceptional properties that match your discerning taste for luxury and exclusivity.</span>
        <h2>Why Choose VMKM Realtors?</h2>
        <span><strong>Expertise:</strong> Backed by years of experience, our team possesses an in-depth knowledge of the real estate landscape in South Delhi, Gurgaon, and Goa. We are well-equipped to assist you in finding the perfect property to suit your lifestyle and investment goals.</span><br></br>
        <span><strong>Luxury Collection:</strong> Our extensive inventory features a handpicked selection of the most luxurious builder floors, apartments, villas, and farmhouses. We ensure that each property meets the highest standards of quality, style, and comfort.</span><br></br>
        <span><strong>Prime Locations:</strong> We bring you properties nestled in premium neighborhoods, boasting excellent connectivity, top-notch amenities, and a lifestyle that suits the elite.</span><br></br>
        <span><strong>Transparency and Trust:</strong> We prioritize transparency in all our dealings. We aim to establish trust and reliability, ensuring that your real estate transactions are executed with integrity and confidence.VMKM Realtors is not just a real estate agency; it's your gateway to a life of luxury and sophistication. Explore the finest properties and invest in a lifestyle you've always dreamed of. Join us on this journey, and let's make your real estate aspirations a beautiful reality.</span><br></br>
        <span><strong>Personalized Service:</strong> At VMKM Realtors, we believe in building lasting relationships. We provide a personalized and tailored approach to cater to your unique requirements, ensuring a seamless and delightful real estate journey.</span><br></br>

        <p>Discover your dream property today with VMKM Realtors! Contact Us to begin your luxury real estate journey with us.</p>

    </div>
</div>
  )
}

export default About
