import React from 'react'
import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            {/* <div className="footer-section">
                <h4>Office Address</h4>
                <p>123 Street Name<br/>City, State, 12345</p>
            </div> */}
            <div className='copyright'>
                <h4>Copyright©️ M/S VMKM ENTERPRISES (OPC) PRIVATE LIMITED. All rights reserved</h4>
            </div>
            <div className='contact'>
            <div className="footer-section">
                <h4>Phone</h4>
                <p>9643491818, 9814306949</p>
            </div>
            <div className="footer-section">
                <h4>Contact</h4>
                <p>Kanav Mehra, Vikas Mehra</p>
            </div>
            <div className="footer-section">
                <h4>Instagram</h4>
                <p><a className="anchor" href="https://instagram.com/vmkmpvt?igshid=MzRlODBiNWFlZA==">vmkmpvt</a></p>
            </div>
            <div className="footer-section">
                <h4>Youtube</h4>
                <p><a className="anchor" href="https://www.youtube.com/@VMKMPVTLTD/featured">@VMKMPVTLTD</a></p>
            </div>
            <div className="footer-section">
                <h4>Email</h4>
                <p>kanav.mehra@vmkmrealtors.com</p>
            </div>
            </div>
  
        </div>
    );
}

export default Footer
