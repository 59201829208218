import React from 'react'
import Header from "../Header/Header";
import Hero from "../Hero/Hero";
import About from "../About/About";

import More from "../MoreLocations/More";
import Footer from "../Footer/Footer";


function MoreLocations() {
  return (
    <div>
        <header className="App-header">
      <Header />
      <More/>
      <Footer />
      </header>
      
    </div>
  )
}

export default MoreLocations
