import React from 'react'

import './Header.css';
// import logo1 from './logo1.png'; // Replace with your logo path
import logo2 from './logo2.png';
import logo3 from './logo3.png';
import { Link } from 'react-router-dom';


const Header = () => {
    return (
        <div className="header">
            <div className="logo-section">
            <img src={logo3} alt="Company Logo" className="logo"/>

            {/* <img src={logo2} alt="Company Logo" npm="logo-vmkm"/> */}
            <Link to="/"> <img src={logo2} alt="Company Logo" className="logo-vmkm"/></Link>
            <p>Home is Happiness</p> 

            </div>
            <div className="nav-section">
            {/* <p className="logo slogan">Home is Happiness</p> */}
            <Link to="/" className="logo slogan" >Home</Link>
            <Link to="/about" className="logo slogan" >About</Link>
            <Link to="/more-locations" className="logo slogan" >Gallery</Link>
            <Link to="/contact" className="logo slogan" >Contact</Link>

            </div>
        </div>
    );
}

export default Header;
