import React from 'react'
import Header from "../Header/Header";
import Hero from "../Hero/Hero";
import About from "../About/About";

import Locations from "../Locations/Locations";
import Footer from "../Footer/Footer";



function Home() {
  return (
    <div>
    <header className="App-header">
      <Header />
      <Hero />
      <About/>
      <Locations/>
      {/* <Testimonials /> */}
      <Footer />
      </header>
    </div>
  )
}

export default Home
